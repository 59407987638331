import React from "react"
import Helmet from "react-helmet"
import { graphql } from 'gatsby'
import Layout from "../components/layout"


export default function ContactPage({ data }) {
  return (
    <Layout>
      <Helmet>
        <title>お問い合わせ | {data.site.siteMetadata.title}</title>
        <meta name="description" content="うしじのブログのお問い合わせページ" />
      </Helmet>
      <iframe title="contact form" src="https://docs.google.com/forms/d/e/1FAIpQLScpe6sXWqI4P9qh3IkJKTNrK-sFFxa_mbfD4twot71FCFDFfg/viewform?embedded=true" width="100%" height="800" frameborder="0" marginheight="0" marginwidth="0">読み込んでいます…</iframe>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ContactPageQuery{
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`